<template>
  <v-container fluid>
    <v-widget title="Contadores" no-padding>
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="8" sm="5">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" max-width="300px" prepend-inner-icon="search" label="Buscar Contador"></v-text-field>
            </v-col>

            <v-col cols="4" sm="7" class="text-right">
              <v-btn depressed fab small :loading="carregando" @click="listar">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>

              <v-btn v-if="!$vuetify.breakpoint.smAndDown" color="primary" depressed @click="adicionar" data-cy="novo" class="ml-2">Novo</v-btn>

              <v-btn v-else depressed fab small color="primary" @click="adicionar" class="ml-2">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:widget-content>
        <v-data-table tile :search="busca" :headers="headers" :items="contadores" :loading="carregando">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.nome }}</td>
              <td>{{ item.crc }}</td>
              <td>{{ item.cidade + ' / ' + item.estado }}</td>
              <td class="text-right">
                <v-menu top right transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id" @click="editar(item)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :key="item._id + 15" @click="remover(item)" data-cy="remover">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { text: 'Nome', value: 'nome', width: '50%' },
        { text: 'CRC', value: 'crc', width: '20%' },
        { text: 'Cidade / Estado', value: 'cidade', width: '30%' },
        { text: '', value: 'acoes', align: 'center', sortable: false, width: '32px' },
      ],
      busca: '',
    };
  },

  computed: {
    ...mapState('contador', {
      contadores: 'contadores',
      carregando: 'carregando',
    }),
  },

  methods: {
    adicionar() {
      this.$store.commit('contador/estadoInicial');
      this.$router.push('/admin/contador/adicionar');
    },

    editar(contador) {
      this.$store.commit('contador/setContador', contador);
      this.$router.push('/contador/editar');
    },

    listar() {
      this.$store.dispatch('contador/listar');
    },

    limparBusca() {
      this.busca = '';
    },

    remover(contadorId) {
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('contador/remover', contadorId);
        }
      });
    },
  },

  mounted() {
    this.listar();
  },
};
</script>
